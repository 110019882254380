<template>
  <div class="page-box">
    <header-bar
      :swiperActiveIndex="3"
      white
    />
    <div class="banner">
      <div>
        <div class="en">ZHANG<span>WAN</span></div>
        <div class="block">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="title2">掌玩人才</div>
        <div class="content2">千里马常有 而伯乐不常有</div>
      </div>
    </div>

    <div class="join-box">
      <!-- <p class="en">JOIN US</p> -->
      <p class="cn">加入我们</p>
      <p class="mail">简历投递：hr@zwwl.info <br /> 联系电话：18072990871（HR小海）</p>

      <div class="join-tab">
        <span
          class="join-tab-item"
          :class="{active:joinTab===1}"
          @click="joinTab=1"
        >校招</span>
        <span
          class="join-tab-item"
          :class="{active:joinTab===2}"
          @click="joinTab=2"
        >社招</span>
        <!-- <span
          class="join-tab-item"
          :class="{active:joinTab===3}"
          @click="joinTab=3"
        >Q&A</span> -->
        <!-- <span
          class="join-tab-item"
          :class="{active:joinTab===3}"
          @click="joinTab=3"
        >Q&A</span> -->
      </div>
      <transition
        name="fade"
        mode="in-out"
      >
        <div
          v-if="joinTab===1"
          class="join-container"
        >
          <div class="page-content popoverList">
            <div
              v-for="(item,index) in contentList"
              :key="index"
              class="popoverListItem"
            >
              <el-popover
                placement="right"
                :title="item.title"
                width='400'
                popper-class="popover"
                trigger="click"
              >
                <div
                  class="workInfo"
                  v-for="(i,j) in item.poper"
                  :key="j"
                >
                  <div class="title">{{i.title}}</div>
                  <p
                    class="contentInfo"
                    v-for="(k,l) in i.content"
                    :key="l"
                  >{{k}}</p>
                </div>
                <div
                  class="zpItem"
                  slot="reference"
                >
                  <p class="zpItemTitle">{{item.title}}</p>
                  <p class="birdge">
                    <span
                      v-for="(x,y) in item.birdge"
                      :key="y"
                    >{{x}}</span>
                  </p>
                </div>
              </el-popover>
            </div>

          </div>
        </div>
        <div
          v-if="joinTab===2"
          class="join-container"
        >
          <div class="page-content popoverList">
            <div
              v-for="(item,index) in contentListSH"
              :key="index"
              class="popoverListItem"
            >
              <el-popover
                placement="right"
                :title="item.title"
                width='400'
                popper-class="popover"
                trigger="click"
              >
                <div
                  class="workInfo"
                  v-for="(i,j) in item.poper"
                  :key="j"
                >
                  <div class="title">{{i.title}}</div>
                  <p
                    class="contentInfo"
                    v-for="(k,l) in i.content"
                    :key="l"
                  >{{k}}</p>
                </div>
                <div
                  class="zpItem"
                  slot="reference"
                >
                  <p class="zpItemTitle">{{item.title}}</p>
                  <p class="birdge">
                    <span
                      v-for="(x,y) in item.birdge"
                      :key="y"
                    >{{x}}</span>
                  </p>
                </div>
              </el-popover>
            </div>
          </div>

        </div>
        <div
          v-if="joinTab===3"
          class="join-tab-3"
        >
          <div class="question-and-answer">
            <div class="question-and-answer-content">
              <div
                class="question-and-answer-item"
                v-for="qa in qaList"
                :key="qa.title"
              >
                <h4>{{ qa.title }}</h4>
                <div class="question-and-answer-detail">
                  <div
                    class="question-and-answer-detail-item"
                    v-for="(item,index) in qa.list"
                    :key="index"
                  >
                    <span class="question-and-answer-detail-question">{{ item.Q }}</span>
                    <span
                      class="question-and-answer-detail-answer"
                      v-html="item.A"
                    ></span>
                  </div>
                </div>
              </div>
              <div class="footer">
                <div class="footer-content">
                  <div class="footer-contact"> 如果有其他想了解的问题，可致电：0571-85360907。</div>
                  <!-- <div class="footer-tip">注意：投递简历请通过<a href="/campus">字节跳动校园招聘官网</a>渠道，此邮箱不作为简历投递使用，会有 HR 在三个工作日内回复～</div>
                  <div class="footer-more">了解更多校园动态、整体安排和线上宣讲会信息，请关注“字节跳动招聘”微信公众号，我们会不定时更新相关内容。</div> -->
                </div>
                <!-- <div class="wechat">
                  <img
                    src="//sf6-ttcdn-tos.pstatp.com/obj/ttfe/ATSX/mainland/bytedance-wechat_1574833970633.jpeg"
                    alt="QRcode"
                    style="width: 80px;"
                  >
                  <div style="margin-top: 12px; font-size: 12px; color: rgb(100, 106, 115);">关注官方招聘公众号</div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <footer-bar />
  </div>
</template>
<script>
export default {
  data() {
    return {
      joinTab: 1,
      scale: 1,
      innerWidth: 1,
      flexWidth: 1,
      qaList: [
        {
          title: '关于简历',
          list: [
            { Q: 'Q：应聘流程具体安排如何？', A: 'A：简历投递-初筛-笔试-面试-offer。' },
            { Q: 'Q：公司接受在校实习生吗？', A: 'A：接受一周可全职工作5天的大三、大四学生，具体情况根据岗位描述为准。' },
            { Q: 'Q：招聘对学历和所学专业有限制吗？', A: 'A：不同的职位对人才的需求会有所不同，有关学历和专业的要求都会在具体的职位描述中呈现。' },
            { Q: 'Q：如何投递简历？', A: 'A：登录掌玩网络官网：https://zhangwan.com/，邮箱是hr@hzzhangwan.com。或在智联、boss、前程或猎聘等平台投递简历，联系HR。' }
          ]
        },
        {
          title: '关于面试',
          list: [
            { Q: 'Q：面试方式有哪些？', A: 'A：面试分为线上和线下两个方式。根据岗位和个人情况定。' },
            { Q: 'Q：简历投递后大概多久会收到反馈?', A: 'A：简历量较大，请耐心等待，5个工作日内会有电话、短信或邮件的形式通知。若没有反馈可电联：0571-85360907。' },
            { Q: 'Q：面试需要准备哪些？', A: 'A：个人简历、作品和荣誉证书。' },
            { Q: 'Q：如何得知面试结果？', A: 'A：3个工作日内会有HR以电话、短信或邮件的形式通知。' }
          ]
        },
        {
          title: '关于掌玩',
          list: [
            {
              Q: 'Q：掌玩是一家什么样的公司？为什么要选择来掌玩？',
              A: `A：掌玩秉承着“人为本、德为先、和为贵、学为乐、拼为荣、果为尺”的价值观，期望更多认可公司价值观的优秀人才加入团队。<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们急需一群优秀的年轻人！<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;你们必须先有梦想、有脑洞、有激情，讨厌循规蹈矩、一成不变；<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;你们可以没有颜值，但必须有才华；你可以没有经验，但必须有创意；<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;你可以没进过大公司，但必须懂互联网；<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;没有勾心斗角的办公室政治和写不完的材料报告，我们只看结果，做得好，你就有成就，有很高的绩效。我们为努力鼓掌，为结果付报酬！<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们位于整个下沙最优质的办公楼—东部国际商务中心，繁华的龙湖天街商业街，网红打卡地，每天都在被人仰望；公司通过多年的奋斗，已经夯实了基础，有优质的内容基因和优质的商务资源，期待更多人加入杭州掌玩网络，续写更加有价值的事业，不然拿什么料向别人吹牛！`
            },
            {
              Q: 'Q：掌玩的福利有哪些？',
              A: `A：【薪资福利】与能力相匹配的薪资水平+五险一金+团队奖金+节假日福利+团建基金+餐补。
              <br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;【年轻化团队】公司90%以上人员为90后，活泼而不失正经，严格但不严肃；在这里，你能找到志同道合的工作伙伴，也能找到兴趣相同的可爱朋友。
              <br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;【丰富化活动】美味多样的下午茶、把你宠上天的女神节......还有各式各样的活动等您来参与！期待您的加入哦～`
            }
          ]
        }
      ],
      contentList: [
        {
          title: '管培生(24届、25届)',
          birdge: ['本科', '专业不限', '带教体系'],
          poper: [
            {
              title: '任职要求:',
              content: ['1、重点大学985、211本科及以上，专业不限；', '2、热爱互联网、新媒体行业；', '3、良好的沟通协调能力，极强的快速学习能力，较强的责任心和进取心；', '4、能够承受工作压力和迎接挑战。']
            },
            {
              title: '培养计划:',
              content: ['第一年：项目深耕+一对一带教+通识技能培训；', '第二年：管理带教+专属定制培养计划；', '第三年：反哺0-1项目+新媒体新兴行业的实战演练。']
            }
          ]
        },
        {
          title: '短视频创意师',
          birdge: ['视频创意', '素材剪辑'],
          poper: [
            {
              title: '我们的工作内容：',
              content: ['日常工作——根据优化师投放的短剧，寻找并剪辑适合推广该短剧的视频素材；', '日常创新——日常寻找对标的在投放热门视频、热点事件，汇总收集其卖点、拆解特性做裂变/创新；']
            },
            {
              title: '任职要求：',
              content: ['短剧控——需要对小说、短剧感兴趣', '基础技能——熟练 ps 、pr等剪辑软件，会抖音短视频剪辑、素材剪辑、包装、配音等技能；', '对热点有高敏感度——有网感、抖音重度用户，追踪各类平台热门视频并捕捉其亮点；', '音乐控——熟知抖音、 B 站平台最近流行的音效、配乐、流行内容；', '专业对口——广告学、设计、数字媒体专业或重度书虫优先考虑。', '可接受优秀的应届毕业生，欢迎投递']
            }
          ]
        }
        // {
        //   title: '在线GM',
        //   birdge: ['杭州市钱塘区', '大专', '运营岗位'],
        //   poper: [
        //     {
        //       title: '岗位职责:',
        //       content: ['1、处理玩家反馈的游戏问题，为玩家提供优质服务，提升游戏体验；', '2、了解用户需求，收集玩家意见，建立良好互动关系，提升玩家满意度；', '3、及时处理与反馈玩家的意见及游戏的异常状况，并帮助玩家快速有效地解决问题；', '4、监控游戏运行状态，维护游戏正常秩序；', '5、体验游戏，对游戏或产品进行反馈并提出改进建议。']
        //     },
        //     {
        //       title: '岗位要求:',
        //       content: ['1、大专及以上学历，经验不限，有客服经验优先考虑', '2、具有较强服务意识、良好的沟通协调能力及应变能力，能灵活处理玩家的各种问题；', '3、耐心、细心，对游戏玩家有一定的了解，能探析玩家心理。']
        //     }
        //   ]
        // }
      ],
      contentListSH: [
        {
          title: '信息流优化师',
          birdge: ['大专', '广告优化'],
          poper: [
            {
              title: '岗位职责：',
              content: ['1.主要负责短剧、小说、游戏其中一个产品在抖音、快手、腾讯渠道上的广告投放计划制定以及日常投放。', '2.每日分析和解读数据，分析市场需求，不断优化广告效果，提高回报率。', '3.监控和研究竞争对手相关策略，并围绕优化提出合理的调整建议。', '4.对广告投放的素材，有提出优化和修改的能力，进行分析，并提供优化策略，使广告投放效果不断优化；完成既定的广告投放目标和效果。', '5.监控和分析广告账户，阶段性总结，保证广告的良性投放，提高广告效果，分析广告文案，实施帐户优化操作。']
            },
            {
              title: '任职要求：',
              content: ['1.熟悉各种流量平台，精通一样大平台投放经验（如头条系，腾讯系，ＭＰ广告），至少一年以上工作经验；', '2.有短剧、小说、游戏投放的相关经验；', '3.大专以上学历，工作认真细致，具备较强的数据分析能力和明确的投放理念；']
            }
          ]
        },
        {
          title: '游戏客服',
          birdge: ['大专', '运营岗位'],
          poper: [
            {
              title: '岗位职责：',
              content: ['1、深度了解游戏用户心理，能对玩家的各种诉求提供合理的解决方案。', '2、负责与游戏VIP玩家建立联系，给VIP玩家提供优质服务，促进玩家的充值流水;', '3、为大R玩家提供专属服务，长期保持良好沟通，提高活跃降低流失;', '4、收集和反馈VIP玩家意见、总结和分析VIP玩家数据并及时汇报。']
            },
            {
              title: '任职要求：',
              content: ['1、了解传奇类游戏，销售经验优先考虑，有游戏行业营销经验最佳；', '2、执行力强，严格执行领导安排的每项工作；', '3、专科以上学历，熟练掌握Office办公软件；']
            }
          ]
        },
        {
          title: '游戏用户运营',
          birdge: ['大专', '运营岗位'],
          poper: [
            {
              title: '岗位职责：',
              content: ['1. 了解传奇类、三国类、仙侠类游戏玩法，入驻具体游戏区服，以合理的方式带动游戏气氛，促进服务器内游戏生态活跃和区服流水。', '2. 建立与游戏玩家良性互动关系进行玩家管理，解决玩家的问题，避免玩家流失，提升用户粘性。', '3. 对玩家进行游戏带教，辅助玩家正确进行游戏，保证用户玩家的游戏体验。', '4. 收集和反馈玩家意见、总结和分析各类玩家数据及时汇报。', '5. 关注游戏线上的运行情况，遇到突发问题及时反应和解决。']
            },
            {
              title: '任职要求：',
              content: ['1. 专业不限，男女不限，年龄20-35岁，大专以上学历，有手游，网游，页游经验者优先。', '2. 对移动游戏感兴趣，思维敏捷、善于沟通，应变能力强；', '3. 具备良好的团队协作、组织协调能力及适当的分析能力；', '4. 有清晰且明确的思维及语言表达能力，熟练掌握OFFICE等办公类软件。']
            }
          ]
        },
        {
          title: '投放助理',
          birdge: ['大专', '广告投放'],
          poper: [
            {
              title: '岗位职责：',
              content: ['1、按照小说、短剧的产品属性、用户需求及广告文案，寻找并创作适合推广的视频广告，展开广告投放工作；', '2、协助投手输出适合的图文、视频素材；', '3、学习头条、抖音、广点通投放后台操作，掌握投放逻辑、投放思路；']
            },
            {
              title: '任职要求：',
              content: ['1、有过抖音、快手、腾讯等渠道的投放经验；', '2、熟悉抖音、快手、微博、微视等短视频平台玩法、套路；', '3、肯吃苦，肯学习，有上进心；']
            }
          ]
        }
      ]
    }
  },
  mounted() {
    this.setScale()
    window.addEventListener('resize', this.setScale)
  },
  methods: {
    setScale() {
      this.scale = window.innerWidth / 1920
      this.innerWidth = window.innerWidth / 2
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.setScale)
  }
}
</script>
<style lang="scss" scoped>
.banner {
  position: relative;
  height: 100%;
  background: url(/bg/6.jpg);
  background-position: center center;
}
.join-box {
  .en,
  .cn,
  .mail {
    text-align: center;
  }
  .en {
    margin-top: 100px;
    font-size: 20px;
  }
  .cn {
    margin-top: 40px;
    font-size: 34px;
    margin-bottom: 20px;
  }
  .mail {
    font-size: 16px;
    margin-bottom: 90px;
  }
  .page-content {
    width: 100%;
    max-width: 1200px;
    .item {
      width: 48%;
      box-sizing: border-box;
      padding: 34px 20px;
      border: 1px solid #e9e9e9;
      margin-bottom: 50px;
      .head {
        display: flex;
        justify-content: space-between;
        line-height: 80px;
        border-bottom: 1px solid #e9e9e9;
        margin-bottom: 20px;
        .job {
          font-size: 20px;
        }
        .num {
          font-size: 16px;
        }
      }
      .content {
        font-size: 12px;
        line-height: 28px;
      }
    }

    .zpItem {
      width: 240px;
      height: 74px;
      background: #ffffff;
      box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.22);
      border-radius: 12px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      .zpItemTitle {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .birdge {
        span {
          background: #f8f8f8;
          border-radius: 3px;
          padding: 2px 5px;
          margin-right: 10px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}
.join-container {
  display: flex;
  justify-content: center;
}
.popoverList {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 80px;
  justify-content: flex-start;
  .popoverListItem {
    margin-bottom: 20px;
    margin-right: 20px;
  }
}

.workInfo {
  .title {
    margin-top: 10px;
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .contentInfo {
    font-size: 14px;
    color: #333333;
    line-height: 28px;
    font-weight: 400;
  }
}

.red {
  color: #f20606;
}

@media screen and(max-width: 996px) {
  .page-content {
    display: block;
    width: 70%;
    .item {
      width: 100%;
    }
  }
}

.join-tab {
  text-align: center;
  margin-bottom: 50px;
  .join-tab-item {
    padding: 0 10px;
    align-items: center;
    // color: #fdfdfd;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    margin: 0 10px;
    &::after {
      content: ' ';
      background: #ed2908;
      height: 2px;
      width: 0.1%;
      position: absolute;
      left: 50%;
      bottom: -10px;
      transition: all 0.2s ease-in-out;
      opacity: 0.1;
    }
    &:hover::after {
      left: 0%;
      width: 100%;
      opacity: 0.9;
    }
    &.active {
      color: #ed2908;
      &::after {
        bottom: -11px;
        height: 3px;
        left: 0%;
        opacity: 0.9;
        width: 100%;
        background: #ed2908;
      }
    }
  }
}

.join-tab-2 {
  .page-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2% 10%;

    .img,
    .content {
      align-self: stretch;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .img {
      width: 30%;
      overflow: hidden;
    }
    .content {
      width: 70%;
    }
    img {
      max-width: 100%;
      // height: 100%;
      display: block;
    }
    .content {
      box-sizing: border-box;
      padding: 0 20px;
      display: flex;
      align-items: center;
      .title,
      .text {
        max-width: 600px;
      }
      .title {
        font-size: 30px;
      }
      .text {
        margin-top: 5%;
        line-height: 34px;
      }
    }
  }
}

.join-tab-3 {
  .question-and-answer {
    display: flex;
    position: relative;
    justify-content: center;
    .question-and-answer-content {
      width: 896px;
      position: relative;

      .question-and-answer-item {
        width: 100%;
        margin-bottom: 32px;
        padding: 35px 40px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: #f8f9fa;
        border-radius: 8px;
        box-sizing: border-box;
        h4 {
          position: relative;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          margin: 0 auto;
          color: #ed2908;
          &:before {
            content: url(~@/assets/title-left.svg);
            position: absolute;
            top: 4px;
            left: -28px;
            width: 19px;
            height: 19px;
          }
          &:after {
            content: url(~@/assets/title-right.svg);
            position: absolute;
            top: 4px;
            right: -28px;
            width: 19px;
            height: 19px;
          }
        }

        .question-and-answer-detail {
          margin-top: 37px;

          .question-and-answer-detail-item {
            padding: 24px 0;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            flex-direction: column;
            position: relative;

            &:first-child {
              padding-top: unset;
            }
            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 1px;
              background-image: linear-gradient(90deg, #ccc 0, #ccc 50%, rgba(0, 0, 0, 0) 0);
              background-size: 8px 1px;
              background-repeat: repeat-x;
            }
            &:last-child:after {
              content: '';
              height: 0;
            }
            .question-and-answer-detail-question {
              margin-bottom: 16px;
              font-size: 16px;
              line-height: 24px;
              color: #1f2329;
            }
            .question-and-answer-detail-answer {
              margin-bottom: 16px;
              font-size: 14px;
              line-height: 24px;
              color: #646a73;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .footer {
        display: -ms-flexbox;
        display: flex;
        padding: 32px 0 32px 40px;
        margin-bottom: 32px;
        background: #f8f9fa;
        .footer-content {
          max-width: 635px;
          margin-right: 40px;
          display: flex;
          flex-direction: column;

          .footer-contact {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 8px;
            color: #1f2329;
          }
          .footer-tip {
            font-size: 14px;
            line-height: 24px;
            padding-bottom: 16px;
            color: #1f2329;
            border-bottom: 1px dashed rgba(187, 191, 196, 0.5);
          }
          .footer-more {
            font-size: 14px;
            line-height: 24px;
            padding-top: 16px;
            color: #646a73;
          }
        }
        .wechat {
          align-self: center;
          width: 180px;
          height: 120px;

          flex-basis: 180px;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-left: 1px solid rgba(187, 191, 196, 0.5);
        }
      }
    }
  }
}
</style>
<style>
.popover {
  padding: 20px;
}
.el-popover__title {
  font-weight: 600;
}
</style>